import React, { useEffect, useState } from "react";
import './styled.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getOrdem, postConfirmModel } from "../../services/ocorrencia";
import { dateFormatBR } from "../../helpers/dateHelper";
import HtmlParser from "react-html-parser";
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from "react-bootstrap/FormGroup";
import Alert from "../toasts/alert";
import ConfirmCancel from "../dialogs/confirmCancel";
import logo from "../../assets/orient-logo.jpg";

function PendenteModal({ showModal, data, handleClose }) {
    const [detalhes, setDetalhes] = useState();
    const [load, setLoad] = useState(true);
    const [itemCodigo, setItemCodigo] = useState("Escolha seu modelo na galeria");
    const [itemRefer, setItemRefer] = useState("");
    const [descrModelo, setDescrModelo] = useState();
    const [alertShow, setAlertShow] = useState(false);
    const [alertData, setAlertData] = useState({
        headerClass: "bg-warning",
        header: "ATENÇÃO",
        body: ""
    });
    const [confirmShow, setConfirmShow] = useState(false);
    const [customerMessage, setCustomerMessage] = useState('Por favor, escolha o modelo dentre as opções apresentadas.')


    useEffect(() => {
        setLoad(true);
        getOrdem(data.uid).then((response) => {
            let payload = {
                ocorrenciaData: {
                    id: response.ocorrenciaData.id,
                    uid: response.ocorrenciaData.uid,
                    ordemServicoCliente: response.ocorrenciaData.ordemServicoCliente,
                    clienteUid: response.ocorrenciaData.clienteUid,
                    cliente: response.ocorrenciaData.cliente,
                    produtoCodigo: response.ocorrenciaData.produtoCodigo,
                    produtoReferencia: response.ocorrenciaData.produtoReferencia,
                    produtoMarca: response.ocorrenciaData.produtoMarca,
                    produtoMarcaSuplente: response.ocorrenciaData.produtoMarcaSuplente,
                    produtoImagem: response.ocorrenciaData.produtoImagem,
                    produtoExisteNaLista: response.ocorrenciaData.produtoExisteNaLista,
                    caixa: response.ocorrenciaData.caixa,
                    caixaDiametro: response.ocorrenciaData.caixaDiametro,
                    caixaEspessura: response.ocorrenciaData.caixaEspessura,
                    caixaAcabamento: response.ocorrenciaData.caixaAcabamento,
                    caixaPeso: response.ocorrenciaData.caixaPeso,
                    pulseira: response.ocorrenciaData.pulseira,
                    vidro: response.ocorrenciaData.vidro,
                    mecanismo: response.ocorrenciaData.mecanismo,
                    linha: response.ocorrenciaData.linha,
                    caracteristicas: response.ocorrenciaData.caracteristicas,
                    produtoDescricao: response.ocorrenciaData.produtoDescricao,
                    produtoSelecionadoCodigo: response.ocorrenciaData.produtoSelecionadoCodigo,
                    produtoSelecionadoReferencia: response.ocorrenciaData.produtoSelecionadoReferencia,
                    produtoSelecionadoPor: response.ocorrenciaData.produtoSelecionadoPor,
                    situacaoId: response.ocorrenciaData.situacaoId,
                    situacao: response.ocorrenciaData.situacao,
                    dataCriacao: response.ocorrenciaData.dataCriacao
                },
                produtos: response.produtos == null ? [] : response.produtos,
                mensagem: response.mensagem
            };
            setDetalhes(payload);
            setDescrModelo(payload.produtos[0]);
            setLoad(false);
            if (payload.ocorrenciaData.produtoSelecionadoReferencia !== null) {
                setItemRefer(payload.ocorrenciaData.produtoSelecionadoReferencia);
            }

            if (payload?.ocorrenciaData?.produtoExisteNaLista === true)
                setCustomerMessage('O modelo de seu relógio está disponível em nosso estoque e pode ser escolhido dentro das opções apresentadas.');

        });
    }, [data.uid]);

    function setSelectedItem(codigo, referencia) {
        setItemCodigo(codigo);
        setItemRefer(referencia);
    }


    function onCarouselClick(idx, item) {
        setSelectedItem(item.props.children[1].props.id, item.props.children[1].props.children);
    }


    function onThumbsClick(idx, item) {
        let itemArray = item.props.id.split("|");
        setSelectedItem(itemArray[0], itemArray[1]);
    }


    function onCarouselChange(idx, item) {
        //setItemRefer("Escolha seu modelo clicando/apertando na imagem");
        var detItem = detalhes.produtos.find((element) => {
            return element.codigoProduto === item.props.children[1].props.id && element.referenciaProduto === item.props.children[1].props.children;
        });
        setSelectedItem(item.props.children[1].props.id, item.props.children[1].props.children);
        setDescrModelo(detItem);
    }

    function callConfirmation() {
        if (itemCodigo === "Escolha seu modelo na galeria") {
            let message = { body: "Por favor, selecione o modelo!" }
            setAlertData(alertData => ({ ...alertData, ...message }));
            setAlertShow(true);
        }
        else
            setConfirmShow(true);
    }

    function confirmModel() {
        setLoad(true);
        setDetalhes(null);
        postConfirmModel(data.uid, { ProdutoSelecionadoCodigo: itemCodigo, ProdutoSelecionadoReferencia: itemRefer }).then();
        setTimeout(() => {
            setLoad(false);
            window.location.reload(true)
        }, 2000);
    }


    return (
        <>

            <Modal size="lg" show={showModal} onHide={handleClose} fullscreen id="mainTroca">

                <Alert show={alertShow} headerClass={alertData.headerClass} headerMessage={alertData.header} bodyMessage={alertData.body} handleClose={setAlertShow} />
                <ConfirmCancel show={confirmShow} headerClass={"bg-success"} bodyMessage={`Confirma a seleção do produto (${itemCodigo} ${itemRefer}) ?`} handleAction={confirmModel} handleClose={setConfirmShow} />

                <Modal.Header closeButton className="bg-secondary text-white">
                    <Modal.Title className="mt-1"> <h4>Ocorrência {data.id}  &nbsp; &nbsp; <label style={{ fontSize: "large" }}> [ OS {data.ordemServicoCliente} ] </label>  </h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-md-center">

                        <Col sm={3} className="modal-body-div">
                            <FormGroup as={Row} className="mb-3 text-center">
                                <Form.Label className="fw-bold">Meu Modelo Original</Form.Label>
                                <p>
                                    {detalhes?.ocorrenciaData?.produtoCodigo + " " + detalhes?.ocorrenciaData?.produtoReferencia}
                                </p>

                                <Row className="mx-auto w-75 mb-3">
                                    {
                                        detalhes?.ocorrenciaData?.produtoImagem ?
                                            <img alt="produto" className="rounded img-fluid" src={detalhes?.ocorrenciaData?.produtoImagem} /> : <Form.Label>imagem não disponível</Form.Label>
                                    }
                                </Row>
                                <p>
                                    {detalhes?.ocorrenciaData?.produtoDescricao}
                                </p>
                                <div style={{ display: "flex", flexDirection: "column", fontSize: "small", overflowY: "auto", maxHeight: "250px", maxWidth: "95%" }}>
                                    <h5>Características</h5>
                                    <label>Caixa: {detalhes?.ocorrenciaData?.caixa}</label>
                                    <label>Diâmetro: {detalhes?.ocorrenciaData?.caixaDiametro}</label>
                                    <label>Espessura: {detalhes?.ocorrenciaData?.caixaEspessura}</label>
                                    <label>Peso: {detalhes?.ocorrenciaData?.caixaPeso}</label>
                                    <label>Pulseira: {detalhes?.ocorrenciaData?.pulseira}</label>
                                    <label>Vidro: {detalhes?.ocorrenciaData?.vidro}</label>
                                    <label>Mecanismo: {detalhes?.ocorrenciaData?.mecanismo}</label>
                                    <label>Adicionais: {detalhes?.ocorrenciaData?.caracteristicas}</label>
                                </div>
                            </FormGroup>
                        </Col>

                        <Col sm={3} className="modal-body-div" style={{ backgroundColor: "#9ca8b3" }}>

                            {load ? <div className="lds-dual-ring" /> : null}

                            <FormGroup as={Row} className="mb-1">
                                <Form.Label column sm={3} className="fw-bold">Data:</Form.Label>
                                <Col sm={9}>
                                    <Form.Control defaultValue={dateFormatBR(data.dataCriacao)} readOnly />
                                </Col>
                            </FormGroup>

                            <FormGroup as={Row} className="mb-3" style={{ backgroundColor: "#9dabb7;" }}>
                                <Form.Label column sm={3} className="fw-bold">Situação:</Form.Label>
                                <Col sm={9}>
                                    <Form.Control defaultValue={data.situacao} readOnly />
                                </Col>
                            </FormGroup>
                            <hr />

                            <div style={{ textAlign: "center" }}>
                                <label>Olá, bem vindo ao portal de troca de modelo!</label>
                                <br />
                                {
                                    (detalhes?.produtos.length === 0) ? null
                                        :
                                        <>
                                            <label>{customerMessage}</label>
                                            <hr />
                                            <FormGroup as={Row} className="mb-2 text-center">
                                                <Form.Label className="fw-bold">Modelo escolhido</Form.Label>
                                                <Col>
                                                    <Form.Control value={itemCodigo + " " + itemRefer} className="text-center mb-2" readOnly />
                                                    {
                                                        detalhes?.ocorrenciaData?.situacaoId !== 1 ?
                                                            <Button variant="success" className="mb-2 w-100" onClick={callConfirmation}>Confirmar</Button>
                                                            : null
                                                    }
                                                </Col>
                                            </FormGroup>
                                        </>
                                }
                            </div>

                            <hr />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Button variant="secondary" onClick={() => handleClose(false)}> Fechar</Button>
                            </div>
                        </Col>

                        <Col sm={4} className="modal-body-div">
                            <Row>
                                <>
                                    {
                                        (detalhes?.produtos.length === 0) ?
                                            <div className="message-no-produtos">
                                                <img alt="logo" className="logo" src={logo} />
                                                <h5> Informa:</h5>
                                                {HtmlParser(detalhes?.mensagem)}
                                            </div>
                                            :
                                            <>
                                                <div className="carousel-placeholder">
                                                    <Carousel
                                                        width="50%"
                                                        //infiniteLoop
                                                        emulateTouch
                                                        useKeyboardArrows
                                                        showArrows
                                                        showIndicators={false}
                                                        onClickItem={onCarouselClick}
                                                        onClickThumb={onThumbsClick}
                                                        onChange={onCarouselChange}
                                                        statusFormatter={(current, total) => `${current} / ${total}`}
                                                        renderArrowNext={(onClickHandler, hasNext, label) => hasNext && (
                                                            <button type="button" onClick={onClickHandler} title={label} className="custom-carousel-button" style={{ right: 0 }}>  {">"} </button>
                                                        )}
                                                        renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && (
                                                            <button type="button" onClick={onClickHandler} title={label} className="custom-carousel-button" style={{ left: 0 }}>  {"<"} </button>
                                                        )}
                                                    //selectedItem={0}
                                                    >
                                                        {
                                                            detalhes?.produtos?.filter(p => p.imagem !== null).map((e, index) => {
                                                                return <div key={index}>
                                                                    <img id={e.codigoProduto + '|' + e.referenciaProduto} src={e.imagem} alt={index} />
                                                                    <p id={e.codigoProduto} className="legend">
                                                                        {e.referenciaProduto}
                                                                    </p>
                                                                </div>
                                                            })
                                                        }
                                                    </Carousel>
                                                </div>
                                                <Row className="text-center">
                                                    <h5>
                                                        {descrModelo?.codigoProduto + " " + descrModelo?.referenciaProduto}
                                                    </h5>
                                                    <p>
                                                        {descrModelo?.descricao}
                                                    </p>
                                                    <div style={{ display: "flex", flexDirection: "column", fontSize: "small", overflowY: "auto", maxHeight: "150px" }}>
                                                        <h5>Características</h5>
                                                        <label>Caixa: {descrModelo?.caixa}</label>
                                                        <label>Diâmetro: {descrModelo?.caixaDiametro}</label>
                                                        <label>Espessura: {descrModelo?.caixaEspessura}</label>
                                                        <label>Peso: {descrModelo?.caixaPeso}</label>
                                                        <label>Pulseira: {descrModelo?.pulseira}</label>
                                                        <label>Vidro: {descrModelo?.vidro}</label>
                                                        <label>Mecanismo: {descrModelo?.mecanismo}</label>
                                                        <label>Adicionais: {descrModelo?.caracteristicas}</label>
                                                    </div>
                                                </Row>
                                            </>
                                    }
                                </>
                            </Row>
                        </Col>

                    </Row>
                </Modal.Body>

                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default PendenteModal;