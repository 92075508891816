import React, { useState } from "react";
import './styled.css';
//components
import PendenteModal from "../pendenteModal";
//Icons
import { BsFillCheckCircleFill, BsFillExclamationSquareFill } from "react-icons/bs"
//Helpers
import { dateFormatBR } from "../../helpers/dateHelper"

function List({ ocorrencia }) {
    const [show, setShow] = useState(false)
    return (
        <>
            <div className='list-content'>
                {
                    ocorrencia.tipo === 1 ? 
                    <p className="key"> <span className="d-small-screen">ID:</span> {ocorrencia.id}</p> 
                    :
                    <p className="key"> <span className="d-small-screen">OS:</span> {ocorrencia.ordemServicoCliente}</p>
                }
                <p className="data-os"> <span className="d-small-screen">Data:</span> {dateFormatBR(ocorrencia.dataCriacao)}</p>
                <p> <span className="d-small-screen">Situação:</span> {ocorrencia.situacaoId === 4 ? <BsFillCheckCircleFill style={{ color: "green", fontSize: "25px" }} /> : <BsFillExclamationSquareFill style={{ color: "yellow", fontSize: "25px" }} />} {ocorrencia.situacao} </p>
                <button onClick={() => setShow(true)} style={{ color: "white", fontWeight: "bold" }}>Abrir</button>
            </div>
            {show ? <PendenteModal showModal={show} data={ocorrencia} handleClose={setShow} /> : null}
        </>
    )
}

export default List