import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import parse from 'react-html-parser';
import './confirmCancel.css';

function ConfirmCancel({show, headerClass, bodyMessage, handleAction, handleClose}) {
  return (
      <Modal show={show} onHide={handleClose} restoreFocus={false}>
        <Modal.Header className={headerClass} closeButton>
          <Modal.Title className="text-white">ATENÇÃO</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">{parse(bodyMessage)}</Modal.Body>
        <Modal.Footer className="">
          <Button variant="secondary" onClick={() => handleClose(false)}> Fechar </Button>
          <Button variant="primary" onClick={() => {handleClose(false); handleAction();}}> Confirmar </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default ConfirmCancel;