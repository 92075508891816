import React from "react";
import './styled.css'
//icon
import ExitIcon from '../../assets/exit_to_app_white_24dp.svg'
import CloseIcon from '../../assets/close_white_24dp.svg'
import PendentIcon from '../../assets/access_time_white_24dp.svg'
import { Link } from "react-router-dom";
import { logout } from "../../config/auth";

function Leftbar({ close }) {
    return (
        <div className="leftbar">
            <div className="close-button" onClick={close}>
                <img src={CloseIcon} alt="close" />
            </div>

            <Link to="/ocorrencia/pendentes">
                <div className="option">
                    <div className="option-items">
                        <img src={PendentIcon} alt="Exit" /><p>Pendentes</p>
                    </div>
                </div>
            </Link>

            <div className="option-exit">
                <div onClick={logout} className="option-items">
                    <img src={ExitIcon} alt="Exit" /><p>Sair</p>
                </div>
            </div>
        </div>
    )
}

export default Leftbar