import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
//componentes
import Login from './Pages/Login';
import Pendentes from './Pages/Pendentes';
import Historico from "./Pages/Historico";
import { isAuthenticated } from "./config/auth";

function App() {
  return (
 <Router>
   { isAuthenticated() ? <Redirect to="/ocorrencia/pendentes"/>: <Redirect to="/"/>}
   <Switch>
     <Route path="/" exact component={Login}/>
     <Route path="/ocorrencia/pendentes" exact component={Pendentes}/>
     <Route path="/ocorrencia/historicos" exact component={Historico}/>

   </Switch>
 </Router>
  )}

export default App;
