import axios from "axios";
import { getToken } from "../config/user";

function getEnvParam(type) {
    let envName = process.env.REACT_APP_BASE;
    switch(envName){
        case "PROD":
            return (type === "LOGIN" ? process.env.REACT_APP_SERVER_API_PROD_LOGIN : process.env.REACT_APP_SERVER_API_PROD);
        case "DEV":
            return (type === "LOGIN" ? process.env.REACT_APP_SERVER_API_DEV_LOGIN : process.env.REACT_APP_SERVER_API_DEV);
        case "HMG":
            return (type === "LOGIN" ? process.env.REACT_APP_SERVER_API_HMG_LOGIN : process.env.REACT_APP_SERVER_API_HMG);
        default:
            return (type === "LOGIN" ? process.env.REACT_APP_SERVER_API_DEV_LOGIN : process.env.REACT_APP_SERVER_API_DEV);
    }
}

const api = axios.create({ baseURL : getEnvParam("API") });

api.interceptors.request.use(async config => {
        const tk = getToken();
        if (tk)
        {
            config.headers.Authorization = `Bearer ${tk}`;
        }
        return config;
    }
);

export default api;

export const apiLogin = axios.create({ baseURL : getEnvParam("LOGIN") });