import React,{useState,useEffect} from "react";

// components
import Topbar from "../../Components/topbar";
import Leftbar from "../../Components/leftbar";
import List from "../../Components/list"; 
import { isAuthenticated } from "../../config/auth"
import { getHistorico } from "../../services/ocorrencia";

// icon
import MenuIcon from '../../assets/menu_white_24dp.svg'
import iconHistorico from '../../assets/history_white_24dp.svg'

function Historico(){
    const [leftbar,setLeftbar] = useState();
    const [historico,setHistorico] = useState();
    const [load,setLoad] = useState();

    useEffect(()=>{
        if (isAuthenticated())
        {
            setLoad(true);
            getHistorico().then((response)=>{
                setHistorico(response)
                setLoad(false);
            });
        }
     },[]);

    return(
        <div className="list-main">
            <Topbar/>
            {leftbar ? <Leftbar close={()=>setLeftbar(false)}/> :
            <div className="menu-icon" onClick={()=>setLeftbar(true)}>
                <img src={MenuIcon} alt="menuicon"/>
            </div>}
            <div className="page-type">
                <img src={iconHistorico} alt="historico"/><h1>Historico</h1>
            </div>
            <div className="list">
                <p>Ordem De Serviço</p>
                <p>Data</p>
                <p>Status</p>
                <p>Ação</p>
            </div>
            {load ? <div class="lds-dual-ring"/> : null}
            { historico ?
                historico.map((e,index)=>{
                    return <List data={e} key={index}/>
                }) : null
            }
        </div>
    );
}

export default Historico