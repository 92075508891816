import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';
import parse from 'react-html-parser';
import './alert.css'

function Alert({show, headerClass, handleClose, headerMessage, bodyMessage}) {
    return (
        <>
            <ToastContainer className="cs-position-top top-2">
                <Toast className="cs-bg-solid-white cs-shadow-box" show={show} onClose={() => handleClose(false)} delay={4000} autohide >
                    <Toast.Header className={headerClass}>
                        <strong className="me-auto text-white fs-4"> {headerMessage} </strong>
                    </Toast.Header>
                    <Toast.Body className="fs-6">{parse(bodyMessage)}</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default Alert;