import { apiLogin } from "./api";
//import jwtDecode from "jwt-decode";

export async function Login({username,password})
{
    localStorage.clear('Token');
    const pass64 = window.btoa(unescape(encodeURIComponent(password)));
    const data = window.btoa(unescape(encodeURIComponent(`${username}:${pass64}`)));
    const response = await apiLogin.post('login/token',null, { headers: {'credential':data}})
        .catch(function(error) {
            if(error)
            {
                return false;   
            }
        });
    if(response)
    {
        const Token = response.data.data.token
        localStorage.setItem('Token', Token);
        //const data = jwtDecode(Token);
        localStorage.setItem('User', username);
        return true;
    }
    else
    {
        return false;
    }
}