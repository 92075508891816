import React, { useEffect, useState } from "react";
import './styled.css';
// components
import Topbar from "../../Components/topbar";
import List from "../../Components/list";
import Leftbar from "../../Components/leftbar";
import { isAuthenticated } from "../../config/auth"
// api
import { getPendentes } from "../../services/ocorrencia";
// icon
import MenuIcon from '../../assets/menu_white_24dp.svg'
import pendentes from '../../assets/access_time_white_24dp.svg'


function OSPendentes() {
    const [leftbar, setLeftbar] = useState();
    const [ordens, setOrdens] = useState([]);
    const [load, setLoad] = useState();
    //const [topMessage, setTopMessage] = useState();

    useEffect(() => {
        if (isAuthenticated()) {
            setLoad(true);
            getPendentes().then((response) => {
                setOrdens(response);
                setLoad(false);
                /*
                if (response.length > 0)
                    setTopMessage("Quantidade de Ocorrências: " + response.length );
                else
                    setTopMessage("Não há Ocorrências pendentes");
                */
            });
        }
    }, []);

    return (
        <>
            <Topbar />
            <div className="list-main">
                {
                    leftbar ? 
                        <Leftbar close={() => setLeftbar(false)} /> 
                        :
                        <div className="menu-icon" onClick={() => setLeftbar(true)}>
                            <img src={MenuIcon} alt="menuicon" />
                        </div>
                }

                <div className="page-type">
                    <img src={pendentes} alt="pendentes" /><label>Pendentes</label>
                </div>

                {/*<h4 className="qtd-ocorrencias"> {topMessage} {ordens.length > 0 ? null : <BsFillEmojiSmileFill style={{ color: "green" }} />} </h4>*/}

                <label>Por favor, clique no botão <strong>Abrir</strong> para acessar a ordem de serviço de seu relógio.</label>

                <div className="list">
                    { ordens[0]?.tipo === 1 ? <p>Ocorrência</p> : <p>OS</p> }
                    <p>Data</p>
                    <p>Status</p>
                    <p>Ação</p>
                </div>
                
                { load ? <div class="lds-dual-ring" /> : null }
                { ordens?.map((e, index) => { return <List ocorrencia={e} key={index} /> }) }

            </div>
        </>
    )
}

export default OSPendentes;