import api from "./api";
import {logout} from "../config/auth";

export async function getPendentes(){
    const response = await api.get("ocorrencias/Pendentes")
    .catch(function(error){
         if(error.response.status === 401){
            logout();
        }
    });
    return response.data.data;
}

export async function getHistorico(){
    const response = await api.get("ocorrencias/Historico")
    .catch(function(error){
        if(error.response.status === 401){
            logout();
        }
    });
    return response.data.data;
}

export async function getOrdem(uid){
    const response = await api.get(`ocorrencias/${uid}`)
    .catch(function(error){
         if(error.response.status === 401){
            logout();
        }
    });
    return response.data.data;
}

export async function postConfirmModel(uid, requestData){
      const response = await api.post(`ocorrencias/Confirmar/${ uid}`,requestData)
        .catch(function(error){
          if(error.response.status === 401 ){
            logout();
          }
      });
      return response.data.data;
}