import React, { useState } from "react";
import './styled.css'
import { Login } from "../../services/login";

//icon
import { Redirect } from "react-router";
import Row from "react-bootstrap/Row";
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup'
import Button from 'react-bootstrap/Button';

function UserLogin() {

    const [redirect, setRedirect] = useState(false);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [load, setLoad] = useState();
    const [error, setError] = useState();

    
    async function login() {
        if (!username && !password) {
            window.alert("Preencha todos os campos")
        }
        else {
            setLoad(true);
            if (await Login({ username, password })) {
                setRedirect(true);
            }
            else {
                setLoad(false);
                setError(true);
            }
        }
    }


    return (
        <div className="main">
            {redirect ? <Redirect to="/ocorrencia/pendentes" /> : null}
            <div className="form-left">

                <Row className="mt-2"> 
                    <label className="login-title"> TROCA MODELO </label>
                </Row>

                <Row className="mb-2">
                    <label className="login-title"> LOGIN </label>
                </Row>

                <Row className="mb-2">
                    <FormGroup>
                        <Form.Label className="fw-bold">Ordem Serviço / Ocorrência</Form.Label>
                        <Form.Control type="username" onChange={(e) => setUsername(e.target.value.trim())} name="username" placeholder="Ex: 1920 ou occ1920" />
                    </FormGroup>
                </Row>

                <Row className="mb-2">
                    <FormGroup>
                        <Form.Label className="fw-bold">Senha</Form.Label>
                        <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} name='username' placeholder="*******" />
                    </FormGroup>
                </Row>

                <Button onClick={login}> {load ? <div className="lds-dual-ring-login" /> : "Acessar"} </Button>

            </div>
            {error ?
                <div className="error">Credenciais inválidas, por favor, tente novamente</div>
                : null}
        </div>
    )
}

export default UserLogin;