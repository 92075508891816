import React from "react";
import './styled.css'
//icon
import UserIcon from '../../assets/account_circle_white_24dp.svg'
import { getUserName } from '../../config/user'

function Topbar() {
    return (
        <div className="topbar-main">
            <div className="user-info">
                <div className="user-area">
                    <img src={UserIcon} alt="user" />
                    <p style={{ marginRight: "5px" }}>{getUserName()} </p>
                </div>
                <div>
                    <p> ORIENT Relógios do Brasil</p>
                </div>
            </div>
        </div>
    )
}

export default Topbar;